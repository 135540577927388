import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';

class ComplaintAccepted extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container complaint-app">
                <div className="section-heading">
                    <h1>DMCA Notice Form</h1>
                    <div className="divider"/>
                </div>
                <p className="fa">
                    Thank you, your request was successfully sent and will be processed.
                </p>
            </div>
        );
    }
}

export default ComplaintAccepted